<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>

export default {
  methods: {
    goto(a){
      this.$router.push({path:'/index'+a});
    }
  },
  data () {
    return {
    }
  },
  computed:{

  },
  mounted(){

  }
}
</script>

